<template>
  <router-view />
</template>

<script>
// import HelloI18n from "./components/HelloI18n.vue";
// import LangSwitcher from "./components/LangSwitcher.vue";


export default {
  name: "App",
  components: {
    // HelloI18n,
    // LangSwitcher,
  }
};
</script>
  
<style src="./assets/Stylesheets/Shared/main.css">

</style>
