// QUI SALVIAMO TUTTI I DATI DEL PROGETTO SCELTI DALL'UTENTE
export default {
  namespaced: true,

  state: () => ({
    scenario: null,
    uploadCount: 0,
    progetto: null /*  TUTTI I DATI RELATIVI AGLI HOTSPOT AGGIUNTI */,
  }),

  actions: {
    updateScenario(context, payload) {
      context.commit("UPDATE_SCENARIO", payload);
    },

    updateUploadCount(context, payload) {
      context.commit("UPDATE_UPLOAD_COUNT", payload);
    },

    updateProgetto(context, payload) {
      context.commit("UPDATE_PROGETTO", payload);
    },
  },

  mutations: {
    UPDATE_SCENARIO(state, payload) {
      state.scenario = payload;
    },

    UPDATE_UPLOAD_COUNT(state, payload) {
      state.uploadCount += payload;
    },

    UPDATE_PROGETTO(state, payload) {
      state.progetto = payload;
    },
  },
};
