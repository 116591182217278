export default {
  namespaced: true,

  state: () => ({
    locales: ["it", "en"],
  }),

  mutations: {
    CHANGE_LANGUAGE(state, payload) {
      payload.i18n.locale = payload.lang;
      localStorage.setItem("locale", payload.i18n.locale);
    },
  },

  actions: {
    updateLang(context, payload) {
      context.commit("CHANGE_LANGUAGE", payload);
    },
  },
};
