import axios from "axios";
import router from "@/router";

export default {
  namespaced: true,

  state: () => ({
    username: "",
    email: "",
    password: "",
    isLogged: false,
  }),

  actions: {
    loginUser({ state }) {
      const userData = {
        password: state.password,
        email: state.email,
      };
      axios
        .post(`${process.env.VUE_APP_BASE_SERVER_URL}/api/login`, userData)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.authorisation.token);
            localStorage.setItem("username", response.data.user.name);
            // if (router.name === "home") {
            router.push({ name: "Dashboard" });
            // } else {
            //   router.push({ name: "Dashboard" });
            // }
          } else {
            router.push({ name: "ErrorView" });
          }
        })
        .catch((error) => {
          console.log("errore login:" + error);
          router.push({ name: "ErrorView" });
        });
    },

    logoutUser() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      axios
        .post(`${process.env.VUE_APP_BASE_SERVER_URL}/api/logout`, {}, config)
        .then((response) => {
          if (response.status === 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            // localStorage.setItem("isConnected", false);
            router.push({ name: "home" });
          }
        })
        .catch((error) => console.log("errore logout:" + error));
    },

    registerUser({ state }) {
      const registerData = {
        name: state.username,
        email: state.email,
        password: state.password,
      };
      axios
        .post(
          `${process.env.VUE_APP_BASE_SERVER_URL}/api/register`,
          registerData
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("REGISTRAZIONE EFFETTUATA CON SUCCESSO");
            router.push("register/success");
          } else {
            router.push({ name: "ErrorView" });
          }
        })
        .catch((error) => {
          console.log("errore registrazione:" + error);
          router.push({ name: "ErrorView" });
        });
    },
  },
};
