import axios from "axios";
// import router from "@/router";

const URL = `${process.env.VUE_APP_BASE_SERVER_URL}/api/settori`;

export default {
  namespaced: true,

  state: () => ({
    settori: [],
    settore_id: null,
    loading: true,
    errored: false,
  }),

  actions: {
    loadData({ commit }) {
      axios.get(URL).then((response) => {
        // console.log(response.data, this)
        commit("updateSettori", response.data);

        // console.log(response.data);
        commit("changeLoadingState", false);
      });
    },

    updateSettore_id(context, payload) {
      context.commit("UPDATE_SETTORE_ID", payload);
    },
  },
  mutations: {
    updateSettori(state, settori) {
      state.settori = settori;
      console.log(state.settori);
    },
    changeLoadingState(state, loading) {
      state.loading = loading;
    },

    UPDATE_SETTORE_ID(state, payload) {
      state.settore_id = payload;
    },
  },
};
