import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store/index";
import router from "./router/index";
import i18n from "./i18n/i18n";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/*import modal*/
import { vfmPlugin } from "vue-final-modal";

/* import specific icons */
import {
  faUserSecret,
  faCircleCheck,
  faChevronLeft,
  faChevronRight,
  faCirclePlay,
  faCube,
  faCommentDots,
  faImage,
  faFileAudio,
  faPodcast,
  faFilePdf,
  faCloudArrowUp,
  faCircleExclamation,
  faPlus,
  faMinus,
  faCheck,
  faRocket,
  faX,
  faBars,
  faCircleUser,
  faChevronDown,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(
  faUserSecret,
  faCircleCheck,
  faChevronLeft,
  faChevronRight,
  faCirclePlay,
  faCube,
  faCommentDots,
  faImage,
  faFileAudio,
  faPodcast,
  faFilePdf,
  faCloudArrowUp,
  faCircleExclamation,
  faPlus,
  faMinus,
  faCheck,
  faRocket,
  faX,
  faBars,
  faCircleUser,
  faChevronDown,
  faTriangleExclamation
);

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(vfmPlugin)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
