import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store/index";

const lazyLoad = (view) => {
  return () => import(`../views/${view}.vue`);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: lazyLoad("Home/Home"),
  },
  {
    path: "/private",
    name: "private",
    component: lazyLoad("Home/Home"),
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "RegisterView",
    component: lazyLoad("RegisterView"),
  },
  {
    path: "/register/success",
    name: "RegisterSuccessView",
    component: lazyLoad("RegisterSuccessView"),
  },
  {
    path: "/login",
    name: "LoginView",
    component: lazyLoad("LoginView"),
  },
  {
    path: "/acquista",
    name: "AcquistaView",
    component: lazyLoad("AcquistaView"),
  },

  {
    path: "/acquista/settore",
    name: "SettoreView",
    component: lazyLoad("Acquista/SettoreView"),
  },
  {
    path: "/acquista/settore/:idsettore",
    name: "Scenari",
    component: lazyLoad("Acquista/ScenariPreviewView"),
  },
  {
    path: "/acquista/settore/:idsettore/:idscenario",
    name: "ScenarioView",
    component: lazyLoad("Acquista/ScenarioView"),
  },
  {
    path: "/acquista/settore/:idsettore/:idscenario/contenuto",
    name: "ContenutoView",
    component: lazyLoad("Acquista/ContenutoView"),
  },
  {
    path: "/acquista/settore/:idsettore/:idscenario/contenuto/progetto",
    name: "ProgettoView",
    component: lazyLoad("Acquista/ProgettoView"),
  },
  {
    // path: "/acquista/settore/:idsettore/:idscenario/contenuto/progetto/dashboard",
    path: "/dashboard",
    name: "Dashboard",
    component: lazyLoad("Dashboard/DashboardView"),
    meta: { requiresAuth: true },
  },
  {
    // path: "/acquista/settore/:idsettore/:idscenario/contenuto/progetto/dashboard",
    path: "/dashboard/comingsoon",
    name: "ComingSoon",
    component: lazyLoad("Dashboard/ComingSoonView"),
    meta: { requiresAuth: true },
  },

  {
    path: "/acquista/success",
    name: "SuccessView",
    component: lazyLoad("Acquista/SuccessView"),
  },
  {
    path: "/acquista/failed",
    name: "FailedView",
    component: lazyLoad("Acquista/FailedView"),
  },
  {
    path: "/error",
    name: "ErrorView",
    component: lazyLoad("ErrorView"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token")) {
      const jwtToken = parseJwt(localStorage.getItem("token"));
      if (
        jwtToken.exp * 1000 < Date.now() ||
        !jwtToken.iss.includes(`${process.env.VUE_APP_BASE_SERVER_URL}/api`)
      ) {
        localStorage.removeItem("token");
        next("/login");
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }

  if (localStorage.getItem("token")) {
    const jwtToken = parseJwt(localStorage.getItem("token"));
    if (
      jwtToken.exp * 1000 < Date.now() ||
      !jwtToken.iss.includes(`${process.env.VUE_APP_BASE_SERVER_URL}/api`)
    ) {
      localStorage.removeItem("token");
      store.state.authFormsModule.isLogged = false;
    } else {
      store.state.authFormsModule.isLogged = true;
    }
  } else {
    localStorage.removeItem("token");
    store.state.authFormsModule.isLogged = false;
  }
});

export default router;
