import { createStore } from "vuex";
import localeModule from "./modules/locale.module.js";
import authFormsModule from "./modules/authForms.module.js";
import settoriModule from "./modules/settori.module.js";
import progettoModule from "./modules/progetto.module.js";

export const store = createStore({
  modules: {
    localeModule: localeModule,
    authFormsModule: authFormsModule,
    settoriModule: settoriModule,
    progettoModule: progettoModule,
  },
});
